import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './ScheduleModal.css'
import { Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { SlashCircle, Trash } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { switchState } from '../Redux/Action/ActionTypes';
import { useSelector } from 'react-redux';
import { AddSchedule, fetchSchedule, fetchSingleSchedule } from '../Redux/Action/ScheduleAction';
import moment from 'moment';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { notifyFailed, notifySuccess } from '../utlity/Notification';

export default function ScheduleModal(props) {
  const { productDataItem } = props;
  const User = useSelector(state => state.User)
  let HouseDetails = useSelector(state => state.HouseDetails)
  let [selectTime,setSelectedTime] = useState( moment(new Date()))
  let [selectOffTime,setSelectedOffTime] = useState( moment(new Date()))
  let [switchData, setSwitchData] = useState([])
  let [repeat,setRepat] = useState("")
  let [name,setName] = useState("")
  let [btn,setBtn] = useState("Submit")
  useEffect(()=>{  
    setSelectedOffTime(moment(new Date()))
    setSelectedTime(moment(new Date()))
  },[])
  useEffect(() => {
    setSelectedTime(moment(new Date()))
    setSelectedOffTime(moment(new Date()))
    let switchs = []
    for (let swit of productDataItem) {
      let obj = {
        "deviceId": swit.deviceId,
        "deviceType": swit.deviceType,
        "roomId": swit.roomId,
        "switchId": swit.switchId,
        "switchState": "1",
        "type": swit.type,
        "switchName": swit.switchName,
        "roomName": swit.roomName,
        "_id":swit._id,
        "houseId":swit.houseId,
        "houseName":swit.houseName
      }
      switchs.push(obj)
      setSwitchData(switchs)
    }
  }, [productDataItem])
  
  let [days, setDays] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  })
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const handleTimeChange = (selectedTime) => {
    // Do something with the selected time
    setSelectedTime(selectedTime)
  };
  const handleTimeOffChange = (selectOffTime) => {
    // Do something with the selected time
    setSelectedOffTime(selectOffTime)
  };
  const handleSubmit= async()=>{
    if(name===""){
      notifyFailed("Plz Enter name");
      return
    }
    if(selectTime===""){
      notifyFailed("Reselect Time")
      return
    }
    if(repeat===""){
      notifyFailed("Plz Select Repeat");
      return
    }
    let day=[]
   Object.keys(days).map(async(d,index)=>{
       if(days[index]===true){
        day.push(index)
       }
    })
    if (day.length===0) {
      notifyFailed("Select Days");
      return
    }
    let houseIds = ["64ba41ca74eca9173f693cbc"]
    // for(let i of switchData){
    //   if(!houseIds.includes(i.houseId)){
    //     houseIds.push(i.houseId)
    //   }
    // }
    let data=[]
    for(let d of houseIds){
      let da ={
        house:d,
        name:name,
        time:new Date(selectTime),
        offtime:new Date(selectOffTime),
        day:day,
        userName:User.userName,
        scene:  undefined,
        states:switchData.filter((e)=>{
          
            return {...e,switchState:"1"}
          
        }),
        repeat:repeat,
        offset: new Date().getTimezoneOffset(),
      }
      data.push(da)
      
    }
     setBtn("Saving...")
     let promises = [];
     let schedulePromise =[]
     for(let v of data){
      promises.push(AddSchedule(v))
     }
     props.onHide()
     setBtn("Submit")
      setRepat("")
      setName("")
      setSelectedTime("")
      setDays({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      })
     await Promise.all(promises);
     for(let id of houseIds){
      schedulePromise.push(fetchSingleSchedule(id))
     }
     await Promise.all(schedulePromise)
    
  }
  const turnOnOff = (checked)=>{
      let newData = switchData.map((s,index)=>{
            if(checked===true){
            return {...s,switchState:"1"}
            }else if(checked===false){
              return {...s,switchState:"0"}
            }
      }
      )
     setSwitchData(newData)
  }
  const removeSwitch =(id)=>{
    let newSwitch = switchData.filter(e=>e._id!==id)
    setSwitchData(newSwitch)
 }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
        <h6>Schedule Name</h6>
        <InputGroup size="sm" className="mb-3">
        
        <Form.Control
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value={name}
          onChange={(e)=>setName(e.target.value)}
        />
      </InputGroup>
      <div style={{display:"flex", gap:"15px"}}>
      <div>
        <h6>Set ON Time</h6>
        <div style={{ width: "20%" }}>
          <DateTime
            onChange={handleTimeChange}
            dateFormat={false}
            input={false}
            closeOnClickOutside={true}
            utc={false}
            timeFormat={true}
            value={selectTime}
          />
        </div>
        </div>
        <div>
        <h6>Set OFF Time</h6>
        <div style={{ width: "20%" }}>
          <DateTime
            onChange={handleTimeOffChange}
            dateFormat={false}
            input={false}
            closeOnClickOutside={true}
            utc={false}
            timeFormat={true}
            value={selectOffTime}
          />
        </div>
        </div>
        </div>
        <h6 style={{ marginTop: "0.5rem" }}>Set Day</h6>
        <div style={{ border: "1px solid #DBDBDB", borderRadius: "12px", padding: "1rem" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Form.Check type="radio" aria-label="radio 1" label="Repeat Once" name="radio" onClick={()=>setRepat("once")}/>
            <Form.Check type="radio" aria-label="radio 2" label="Repeat Every" name="radio" onClick={()=>setRepat("every")}/>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "1rem" }}>
            {
              daysOfWeek && (
                daysOfWeek.map((day, index) => {
               
                  return (
                    <Button
                      size='sm'
                      variant={days[index] === false ? "outline-primary" : "primary"}
                      key={index}
                      onClick={() => setDays({ ...days, [index]: !days[index] })}
                    >
                      {day}
                    </Button>
                  )
                })
              )
            }
          </div>
        </div>
        <h6 style={{ marginTop: "0.5rem" }}>Set Appliance State</h6>

        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Property</th>
              <th>Area</th>
              <th>Appliance</th>
              {/* <th>Sechedule to <BootstrapSwitchButton checked={true} size="sm"  onChange={(checked) => {
                      turnOnOff(checked)
                   }}/>
                   
                   </th> */}
              
            </tr>
          </thead>
          <tbody>
            {
              switchData && (
                switchData.map((swit,i) => {
                  return (
                    <tr key={i+"switch"}>
                      <td>1</td>
                      <td>{swit.houseName}</td>
                      <td>{swit.roomName}</td>
                      <td>{swit.switchName}</td>
                      {/* <td>
                        <Stack direction="horizontal" gap={3}>
                          <Button
                            variant={swit.switchState != "0" ? "primary" : "outline-secondary"}
                          
                            size='sm'
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={() => {
                              let newData = switchData.map((s,index)=>{
                                if(swit._id===s._id){
                                  
                                  if (s.switchState == "0") {
                                   return {...s,switchState:"1"}
                                  }else  if (s.switchState != "0") {
                                    return {...s,switchState:"0"}
                                  }
                                  else{
                                    return swit
                                  }
                                }else{
                                  return s
                                }
                              })
                            
                              setSwitchData(newData)
                            }}
                          >
                            <SlashCircle style={{ marginRight: "5px" }} />
                            Turn On
                          </Button>
                          <Button
                            variant={swit.switchState == "0" ? "primary" : "outline-secondary"}
                            size='sm'
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={() => {
                                
                              let newData = switchData.map((s,index)=>{
                                  if(swit._id===s._id){
                                   
                                    if (s.switchState == "0") {
                                     return {...s,switchState:"1"}
                                    }else  if (s.switchState != "0") {
                                      return {...s,switchState:"0"}
                                    }
                                    else{
                                      return swit
                                    }
                                  }else{
                                    return s
                                  }
                                })
                                setSwitchData(newData)
                              
                             
                            }}
                          >
                            <SlashCircle style={{ marginRight: "5px" }} />
                            Turn Off
                          </Button>
                        </Stack>
                      </td> */}
                      <td><Trash onClick={()=>removeSwitch(swit._id)} style={{cursor:"pointer"}}/></td>

                    </tr>
                  )
                })
              )
            }


          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>handleSubmit()} style={{pointerEvents:btn==="Submit"?"":"none"}} variant={btn==="Submit"?"primary":"secondary"}>{btn}</Button>
      </Modal.Footer>
    </Modal>
  );
}


