import { getProperties, house, switchState } from "../Action/ActionTypes";

export const PropertyDetails = (state = {}, action) => {
    switch (action.type) {
        case getProperties.GET_ALL_PROPERTY:
            return action.payload
        case getProperties.UPDATE_PROPERTIES:
            let pro = state.property
             pro=pro.map(p=>{
                if(action.payload._id===p._id){
                    return action.payload
                }else{
                   return p
                }
             })   
             return {...state,property:pro}     
        case getProperties.EMPTY_PROPERTIES:
            return action.payload
        default:
            return state
    }
}

export const HouseDetails = (state = [], action) => {
    switch (action.type) {
        case house.FETCH_HOUSE:
            return action.payload
        case house.UPDATE_HOUSE:
            let newHo =[]
            for(let h of state){
                if(h.id===action.payload.id){
                    newHo.push(action.payload)
                }else{
                    newHo.push(h)
                }
            }
            console.log(newHo,"new house");
            return newHo
        case switchState.SWITCH_ON:
            
      let newData= state.map((house)=>{
        const newHouse = {...house}
        
        if(house.house._id===action.payload.houseId){
            newHouse.rooms = house.rooms.map((room)=>{
                const newRoom = {...room};
                newRoom.devices = room.devices.map((device)=>{
                    let newDevice ={...device};
                    if (device.deviceId === action.payload.devicesId) {
                     newDevice.switches = newDevice.switches.map(appliance => {
                        if (Number(appliance.switchId) === Number(action.payload.switch.switchId)) {
                            return {
                                ...appliance,
                                ...action.payload.switch,
                            }
                        } else {
                            return appliance;
                        }
                     })       
                    }
                    return newDevice;
                })
                return newRoom;
          })
        }
            return newHouse;
        })    
        
        return newData
        case house.EMPTY_HOUSE:
            return action.payload
        default:
            return state
    }

}