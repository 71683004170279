import React from 'react'
import { FadeLoader } from 'react-spinners'

export default function Spinner() {
  return (
    <div style={{position:"fixed",top:0,left:0,height:"100vh",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <div>
        <FadeLoader
           loading={true}
           color={"#79A9F5"}
           size={150}
        />
        </div>
    </div>
  )
}
