import axios from 'axios'
import React from 'react'
import {Wifi,WifiOff} from 'react-bootstrap-icons'
import { serverUrl } from '../../ApiUri'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'

export default function DeviceOnLIneOffLine({deviceId,mqt,switchState}) {
    let [online,setOnline] = useState("")
    const deviceCheck = ()=>{
        if(mqt===false){
        axios.get(`${serverUrl.a3}/v2/admin/deviceOnline/${deviceId}`).then(function (res) {
            if(res.status===200){
                setOnline(res.data.status)
            }
        }).catch(function (error) {
            
            setOnline('offline')
        })
    }else{
        axios.post(`${serverUrl.mqt}/connectionStatus`,{
            deviceId:deviceId
        }).then(function (res) {
            if(res.data.success===true){
                console.log(res,"mqt wifi");
                if(res.data.data.connected===true){
                    setOnline("online") 
                    
                }else{
                    setOnline("offline")
                }
            }else{
                setOnline("offline")
            }
        }).catch(function (error) {
            
            setOnline('offline')
        })
    }
    }
    useEffect(()=>{deviceCheck()},[])
  return (
    <>
    {
       switchState !== "0"? <Button variant="outline-success" size='sm' style={{ backgroundColor: "rgba(115, 241, 191, 0.3)" }}>Occupied</Button>:<Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">UnOccupied</Button>
    }
    </>
  )
}
