import { logs } from "../Action/ActionTypes";

export const toadayLogReducer =(state={},action)=>{
    switch (action.type) {
        case logs.TODAY_GET_LOGS:
             return action.payload            
        case logs.EMPTY_TODAY_LOGS:
             return action.payload
        default:
            return state
    }
}