import axios from "axios";
import store from "../index";
import { autocut } from "./ActionTypes";
import { serverUrl } from "../../../ApiUri";

export const getAutoCut = (data)=>{
     return{
        type:autocut.ADD_AUTOCUT,
        payload:data
     }
}
export const emptyAutoCut = (data)=>{
    return{
       type:autocut.EMPTY_AUTOCUT,
       payload:data
    }
}
export const updateAutoCut = (data)=>{
    return{
       type:autocut.UPDATE_AUTOCUT,
       payload:data
    }
}



export const fetchAutoCut=async(houseid)=>{
  
  return await axios.post(`${serverUrl.main}/app/stanza/getAutocut`,{
        houseId:houseid
    }).then(function (res) {
        if(res.data.success===true){
            return res.data.data
        }else{
            return
        }
    }).catch(function (err) {
        console.log(err);
        return
    })
}

