import { autocut } from "../Action/ActionTypes";

export const storeAutoCut = (state = [], action) => {
    switch (action.type) {
        case autocut.ADD_AUTOCUT:
            return action.payload
        case autocut.UPDATE_AUTOCUT:
            let array1 = [...state];
            let array2 = [...action.payload];
            // Step 2: Identify common objects
            const commonObjects = array1.filter(obj1 =>
                array2.some(obj2 => (obj1.deviceId === obj2.deviceId && obj1.switchId === obj2.switchId))
            );

            // Step 3: Remove common objects from the first array
            commonObjects.forEach(commonObj => {
                const index = array1.findIndex(obj => obj._id === commonObj._id);
                if (index !== -1) {
                    array1.splice(index, 1);
                }
            });

            // Step 4: Merge the two arrays
            const mergedArray = [...array1, ...array2];
            return mergedArray
        case autocut.EMPTY_AUTOCUT:
            return action.payload
        default:
            return state;
    }

}