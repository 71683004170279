import { Button, Form, Stack, Tab, Tabs } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { SlashCircle } from "react-bootstrap-icons";
import "./TableData.css";
import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import ScheduleModal from "./ScheduleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  SelctedSwitchMannualBlock,
  TurnOffAllApi,
  TurnOffApi,
  TurnOnAllApi,
  TurnOnApi,
  deviceBlock,
} from "../Redux/Action/PropertyAction";
import { todayLogSave, todayLogs } from "../Redux/Action/LogsAction";
import LogModel from "./LogModel";
import axios from "axios";
import OnTimeRow from "./OnTimeRow";
import FetchScheduleRow from "./FetchScheduleRow";
import DeviceOnLIneOffLine from "./DeviceOnLIneOffLine";
import LogModalOnTime from "./LogModalOnTime";
import { house } from "../Redux/Action/ActionTypes";
import ViewLogbtn from "./ViewLogbtn";
import ConfirmationModel from "./ConfirmationModel";
import AutoCutApi from "./AutoCutApi";
import { notifyFailed, notifySuccess } from "../utlity/Notification";
import EditHouseDetails from "./EditHouseDetails";
import AutoCutMap from "./AutoCutMap";
import OccupiedOunocupied from "./OccupiedOunocupied";

function TableData({
  citys,
  selectedCity,
  selectedAppliance,
  selectedRoom,
  selectedProperty,
  city,
}) {
  let HouseDetails = useSelector((state) =>
    state.HouseDetails.filter((h) => h.city === selectedCity)
  );
  let PropertyDetails = useSelector((state) => state.PropertyDetails);
  let User = useSelector((state) => state.User);
  const schedule = useSelector((state) => state.schedule);
  let [logSwitch, setLogSwitch] = useState({});
  let [productDataItem, setProductDataItem] = useState([]);
  let [switchesData, setSwitchesData] = useState([]);
  let dispatch = useDispatch(null);
  const [modalShow, setModalShow] = useState(false);
  const [logModalShow, setLogModalShow] = useState(false);

  const todayLogOntime = async (deviceId, switchId) => {
    return await axios
      .post(
        `https://analytics.alistetechnologies.com:443/analytic/TodayLogsByDateDevice`,
        {
          deviceId: deviceId,
          roomId: "DFS",
        }
      )
      .then(function (res) {
        if (res.data.final.length !== 0) {
          let filerSwitch = res.data.final.find((d) => {
            if (d.deviceId === deviceId && d.switchId === switchId) {
              return d;
            }
          });
          const durationInMilliseconds = filerSwitch.ontime;

          const seconds = Math.floor(durationInMilliseconds / 1000);
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          const remainingSeconds = seconds % 60;

          const formattedTime = `${hours}:${minutes
            .toString()
            .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
          return formattedTime;
        } else {
          return "0";
        }
      })
      .catch(function (err) {
        //console.log(err);
        return "0";
      });
  };

  const sorts = () => {
    let total = [];
    HouseDetails.map((house, ho) => {
      if (Object.keys(house).length !== 0 && house.rooms.length !== 0) {
        return house.rooms.map((room, roi) => {
          return room.devices.map((device, di) => {
            return device.switches.filter((p, i) => {
              total.push({ ...p, device, room, house });
            });
          });
        });
      }
    });
    //console.log(total,"total");
    let to = [];
    let num = 1000;
    for (let t of total) {
      let NewswitchName = t.switchName.trim().substring(0, 3);
      //console.log(NewswitchName);
      if (NewswitchName === "Roo") {
        let newName = t.switchName.trim().slice(-3);

        NewswitchName = newName;
      } else if (isNaN(NewswitchName)) {
        num += 1;
        NewswitchName = num;
      }
      to.push({ sortingNumber: Number(NewswitchName), ...t });
    }
    return to;
  };

  useEffect(() => {
    if (HouseDetails.length === 0) {
      setSwitchesData([]);
      return;
    }
    if (switchesData.length === 0) {
      let data = sorts();
      data = data.sort((a, b) => (a.sortingNumber > b.sortingNumber ? 1 : -1));

      setSwitchesData(data);
    }
  }, [HouseDetails]);

  //console.log('fffffffff',HouseDetails);
  return (
    <div style={{ paddingTop: "15px", backgroundColor: "#ffffff" }}>
      <ScheduleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        productDataItem={productDataItem}
      />

      <LogModalOnTime
        show={logModalShow}
        onHide={() => {
          setLogModalShow(false);
          // dispatch(todayLogSave({success:true,message:"No Logs Found",final:[]}))
        }}
        logSwitch={logSwitch}
      />
      <div
        style={{
          textAlign: "left",
          width: "fit-content",
          marginLeft: "30px",
          padding: "0.6rem",
          borderTopRightRadius: "10.2519px",
          borderTopLeftRadius: "10.2519px",
          backgroundColor: "#F9F9FB",
        }}
      >
        Breakdown
      </div>
      <div style={{ backgroundColor: "#F9F9FB" }}>
        <Stack
          direction="horizontal"
          gap={3}
          style={{
            marginLeft: "60px",
            paddingTop: "15px",
            marginBottom: "15px",
          }}
        ></Stack>
        <Table
          hover
          style={{
            textAlign: "left",
            marginLeft: "30px",
            width: "96%",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              {/* <th> <Form.Check aria-label="option 1" name="AllChk" id="AllChk" onChange={(event)=>selectAllBox(event.target.checked)}/></th> */}
              <th className="tableHeading">Room Name</th>
              <th className="tableHeading">Appliance Name</th>
              {/* <th className='tableHeading'>Current Status</th> */}
              <th className="tableHeading">Status</th>
              <th className="tableHeading">Action</th>
            </tr>
          </thead>
          <tbody>
            {switchesData.length !== 0
              ? switchesData.map((switchs, index) => {
                  if (
                    switchs.switchName.includes(selectedAppliance) &&
                    switchs.room.roomName.includes(selectedRoom) &&
                    switchs.deviceType !== 7 &&
                    switchs.house.propertyName.includes(selectedProperty)
                  ) {
                    return (
                      <tr key={switchs._id}>
                        <td className="tableData">
                          {switchs.room.roomName}
                     
                        </td>

                        <td className="tableData">
                          {switchs.switchName}
                       
                        </td>
                        {/* <td className='tableData'><OccupiedOunocupied logSwitch={{...switchs,deviceId:device.deviceId,mqt:device.isMQTTDevice,houseName:house.propertyName,roomName:room.roomName,roomId:room._id,deviceType:switchs.deviceType,type:device.deviceId.substring(1,3)=="08"?"nova":"sync"}}/></td> */}
                        <td className="tableData">
                          <DeviceOnLIneOffLine
                            deviceId={switchs.device.deviceId}
                            mqt={switchs.device.isMQTTDevice}
                            switchState={switchs.switchState}
                          />
                        </td>
                        <td className="tableData">
                          <Stack direction="horizontal" gap={3}>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                setLogSwitch({
                                  ...switchs,
                                  deviceId: switchs.device.deviceId,
                                  mqt: switchs.device.isMQTTDevice,
                                  houseName: switchs.house.propertyName,
                                  roomName: switchs.room.roomName,
                                  roomId: switchs.room._id,
                                  deviceType: switchs.deviceType,
                                  type:
                                    switchs.device.deviceId.substring(1, 3) ==
                                    "08"
                                      ? "nova"
                                      : "sync",
                                });
                                setLogModalShow(true);
                              }}
                            >
                              <SlashCircle style={{ marginRight: "5px" }} />
                              View Logs
                            </Button>
                            {/* <EditHouseDetails data={{...switchs,deviceId:device.deviceId,mqt:device.isMQTTDevice,houseName:house.propertyName,houseId:house.house._id,roomName:room.roomName,roomId:room._id,deviceType:switchs.deviceType,type:device.deviceId.substring(1,3)=="08"?"nova":"sync",city:house.city}} city={citys}/> */}
                          </Stack>
                        </td>
                      </tr>
                    );
                  }
                })
              : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default TableData;
