import React from 'react'
import { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

function WifiSessions({wifiTime}) {
    let [open,setOpen] = useState(false)
  return (
    <>
      <Button
        onClick={()=>setOpen(true)}
      >Detail</Button>

      <Modal
            show={open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <div style={{ textAlign: "right" }}>
                    <span
                        onClick={() => {
                            setOpen(false)
                        }}
                        style={{ fontSize: "18px", cursor: "pointer" }}
                    >X</span>
                </div>
                {
                    (wifiTime.length !== 0) ?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Connection Start</th>
                                    <th>Connection End</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    wifiTime.map((e, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i+1}</td>
                                                    <td>{e[1].realConnectedTime==="Invalid Date"?"":e[1].realConnectedTime}</td>
                                                    <td>{e[1].realDisconnectedTime==="Invalid Date"?"":e[1].realDisconnectedTime}</td>
                                                </tr>
                                            )
                                        
                                    })
                                }
                            </tbody>
                        </Table> : <h5>{"Sessions Not Available"}</h5>
                }


            </Modal.Body>

        </Modal>
    </>
  )
}

export default WifiSessions